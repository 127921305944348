@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200..600&display=swap');

:root {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
}

@font-face {
  font-family: 'Body-Font'; /* Font family name you'll use in CSS */
  src: url('https://reviews-jet.sfo3.cdn.digitaloceanspaces.com/@FlexByt/widgets/product-widget/fonts/Jost-Regular.ttf')
    format('truetype'); /* Path to the OTF font file */
  font-weight: normal;
  font-style: italic;
}

.assistant-400 {
  font-family: 'Assistant', sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.assistant-500 {
  font-family: 'Assistant', sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}
