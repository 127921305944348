.reviews-jet-rate {
  padding: 10px;
}
.reviews-jet-rate label {
  padding: 0px;
}
.reviews-jet-rate:not(:checked) > input {
  position: absolute;
  top: -9999px;
}
.reviews-jet-rate:not(:checked) > label {
  float: right;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 35px;
  color: #ccc;
}
.reviews-jet-rate:not(:checked) > label:before {
  content: '★';
}
.reviews-jet-rate > input:checked ~ label {
  color: #ffc700;
}
.reviews-jet-rate:not(:checked) > label:hover,
.reviews-jet-rate:not(:checked) > label:hover ~ label {
  color: #deb217;
}
.reviews-jet-rate > input:checked + label:hover,
.reviews-jet-rate > input:checked + label:hover ~ label,
.reviews-jet-rate > input:checked ~ label:hover,
.reviews-jet-rate > input:checked ~ label:hover ~ label,
.reviews-jet-rate > label:hover ~ input:checked ~ label {
  color: #c59b08;
}
.reviews-jet-rate input[name='rate'] {
  display: none;
}
