/* Overlay: Smooth dark background */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7); /* Sleek darker overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fade-in 0.3s ease-in-out; /* Smooth fade-in animation */
}

/* Modal Content: Polished box with subtle shadow */
.modal-content {
  background: white;
  border-radius: 0; /* Default: no rounded corners */
  width: 95%; /* Default width for larger screens */
  max-width: 500px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.3); /* Smooth shadow */
  position: relative;
  transform: translateY(-20px);
  animation: slide-in 0.3s ease-out forwards; /* Slide-in effect */
  /* padding: 20px; */
}

/* Close Button: Styled with hover and focus states */
.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  background: transparent;
  border: none;
  width: 43px;
  height: 43px;
  cursor: pointer;
  color: #333;
  font-weight: bold;
  transition: color 0.2s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-close:hover {
  background-color: #cccccc4d;
}

.modal-close:hover,
.modal-close:focus {
  color: #000; /* Bright color on hover */
  outline: none;
}

/* Animations */
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slide-in {
  from {
    transform: translateY(-20px);
  }
  to {
    transform: translateY(0);
  }
}

/* Responsive Styles */
@media (max-width: 600px) {
  .modal-content {
    width: 100%;
    height: 100%;
    max-width: none;
    border-radius: 0;
    margin: 0;
    box-shadow: none;
    transform: none;
    max-width: 100% !important;
  }
}
