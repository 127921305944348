.container {
  z-index: 1;
}

.particle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 50%;
  animation: move 10s infinite;
}

@keyframes move {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(100vw, 100vh, 0);
  }
}
