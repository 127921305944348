/* Dropdown container */
.dropdown {
  position: relative;
  display: inline-block;
  width: 50%;
}

/* Button styling */
.dropdown-button {
  background-color: #fff;
  color: #333333;
  border: none;
  padding: 10px;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease;
  border: 1px solid #ebebeb;
  display: flex;
  width: 100%;
  max-width: 150px;
  gap: 10px;
}

.dropdown-button:hover {
  background-color: #f3f3f3;
}

/* Dropdown menu styling */
.dropdown-menu {
  position: absolute;
  top: 102%;
  left: 0;
  background-color: white;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(58, 58, 58, 0.1);
  list-style: none;
  margin: 0;
  padding: 0;
  max-width: 200px;
  width: 100%;
  z-index: 1000;
}

/* Dropdown option styling */
.dropdown-option {
  padding: 10px;
  font-size: 14px;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dropdown-option:hover {
  background-color: #f0f0f0;
}

/* Close the dropdown when clicking outside */
body {
  margin: 0;
  font-family: Arial, sans-serif;
}
