.rs-modal-wrapper {
  top: 5% !important;
}
.rs-modal-content {
  /* border-radius: 20px !important; */
}
.rs-modal-header span {
  font-size: 15px !important;
  cursor: pointer;
  display: block;
}
.rs-modal-header span svg {
  cursor: pointer;
  font-weight: bold;
}
li.rs-rate-character.rs-rate-character-full {
  margin-right: 5px;
}
li.rs-rate-character.rs-rate-character-empty {
  margin-right: 5px;
}
.pending .rs-toggle-presentation,
.pending .rs-toggle-presentation:hover {
  background-color: #ca4747 !important;
}
.published .rs-toggle-presentation,
.published .rs-toggle-presentation:hover {
  background-color: #0ba32e !important;
}
a,
a:hover,
a:active,
a:visited,
a:focus {
  text-decoration: none !important;
}
.rsuite-color-picker {
  min-width: 40% !important;
}
.settings .rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean {
  display: none;
}
.translation .rs-input {
  border-radius: 10px;
}
.translation .rs-input:hover,
.translation .rs-input:focus {
  background-color: #f5f5f5;
}
.form-widget li.rs-rate-character.rs-rate-character-full,
li.rs-rate-character.rs-rate-character-half,
li.rs-rate-character.rs-rate-character-empty {
  /* margin-right: 5px;
  color: black; */
}
.form-widget li.rs-rate-character.rs-rate-character-empty {
  margin-right: 0;
}

.excel-upload
  .rs-uploader-draggable
  .rs-uploader-trigger-customize
  .rs-uploader-trigger-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
/* .feedback-form .rs-drawer-header + .rs-drawer-body {
  padding: 0 36px 0 36px;
}
.feedback-form .rs-drawer-header .rs-drawer-header-close {
  left: 92% !important;
}
.feedback-form .rs-drawer-header {
  padding: 10px 34px 9px 40px !important;
} */
.feedback-form .rs-radio.rs-radio-checked .rs-radio-inner::before {
  background-color: transparent !important;
  border: 3px solid white !important;
}
.feedback-form .rs-radio-inner::after {
  background-color: black !important;
}
.rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean {
  display: none;
}
.feedback-form .rs-uploader-trigger.rs-uploader-trigger-customize {
  cursor: default;
}

.upgrade-notice .rs-modal-content {
  padding: 0 !important;
  width: 576px;
  height: 540px;
}

.upload-modal
  .rs-uploader-draggable
  .rs-uploader-trigger-customize
  .rs-uploader-trigger-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.rs-table-cell {
  max-width: 90% !important;
}

.rs-dropdown-menu {
  padding: 0 0 !important;
}
